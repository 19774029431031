<!-- NumberComponent.vue -->
<template>
	<!-- <pui-number-field
    :label="$t(componentData.label)"
    toplabel
    :required = componentData.required
    :style="componentData.style"
    :min="componentData.min"
    :max="componentData.max"
  ></pui-number-field> -->
	<pui-number-field
		:label="$t(componentData.label)"
		decimals="2"
		:min="componentData.min"
		:max="componentData.max"
		:required="componentData.required"
		:class="componentData.class"
		toplabel
		v-model="inputData"
	></pui-number-field>
</template>

<script>
//import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	//mixins: [PuiFormMethodsMixin],
	props: ['componentData'],
	data() {
		return {
			inputData: null
		};
	},
	watch: {
		inputData() {
			console.log('inputData (select)', this.inputData);
			let propName = this.componentData.propertyName || 'value';
			this.$emit('input', { [propName]: this.inputData });
		}
	}
};
</script>
