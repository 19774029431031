<!-- SelectComponent.vue -->
<template>
	<div v-if="componentData.internalModel">
		<pui-select
			:label="$t(componentData.label)"
			:required="componentData.required"
			:placeholder="$t(componentData.placeholder)"
			toplabel
			:class="componentData.class"
			:modelName="componentData.modelName"
			:itemValue="componentData.itemValue"
			:itemText="componentData.itemText"
			v-model="inputData"
		></pui-select>
	</div>
	<div v-else>
		<v-select
			:label="$t(componentData.label)"
			:required="componentData.required"
			:placeholder="$t(componentData.placeholder)"
			:items="componentData.itemsToSelect"
			:class="componentData.class"
			item-value="itemValue"
			item-text="itemText"
			v-model="inputData"
		></v-select>
	</div>
</template>

<script>
//import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	//mixins: [PuiFormMethodsMixin],

	props: ['componentData'],
	data() {
		return {
			inputData: null
		};
	},
	watch: {
		inputData() {
			console.log('inputData (select)', this.inputData);
			let propName = this.componentData.propertyName || 'value';
			this.$emit('input', { [propName]: this.inputData });
		}
	}
};
</script>
