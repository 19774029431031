<template>
	<div class="d-flex dynamic-slider">
		<v-slider
			v-model="slider"
			class="ma-0 ml-1"
			:color="componentData.color || 'primary'"
			:max="componentData.max || 100"
			:min="componentData.min || 0"
			:step="componentData.step || 10"
			:label="componentData.label || 'Slider'"
			:style="componentData.style || ''"
		>
			<!-- <template v-slot:prepend>
				<v-btn elevation="0" min-width="32" width="32" height="32" class="" @click="decrement">
					<v-icon class="fak fa-line-less-zoom fw-500 pt-1" size="16" color="primary"></v-icon>
				</v-btn>
			</template> -->

			<template v-slot:append>
				<!-- <v-btn elevation="0" min-width="32" width="32" height="32" @click="increment">
					<v-icon class="fak fa-line-more-zoom fw-500 pt-1" size="16" color="primary"></v-icon>
				</v-btn> -->
				<v-text-field v-model="slider" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px"></v-text-field>
			</template>
		</v-slider>
		<!-- <v-text-field
			v-model="slider"
			class="ml-2 mt-0 pt-0"
			hide-details
			single-line
			type="number"
			style="max-width: 60px !important"
		></v-text-field> -->
	</div>
</template>

<script>
export default {
	props: ['componentData'],
	data() {
		return {
			slider: 0
		};
	},
	watch: {
		slider() {
			let propName = this.componentData.propertyName || 'value';
			console.log('inputData (slider)', this.slider, propName);
			this.$emit('input', { [propName]: this.slider });
		}
	},
	mounted() {
		if (this.componentData.value) {
			this.slider = this.componentData.value;
		}
	},
	methods: {
		decrement() {
			console.log('decrement');
			this.slider -= this.componentData.step || 10;
		},
		increment() {
			console.log('increment');
			this.slider += this.componentData.step || 10;
		}
	}
};
</script>

<style lang="postcss">
.dynamic-slider {
	& .v-input__slot {
		margin: 0 !important;
	}
	& .v-slider {
		margin-top: 8px !important;
	}
}
</style>
