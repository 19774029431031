<template>
	<v-card class="overflow-hidden">
		<v-card-title style="background-color: var(--azul-primary-100)" class="fs-lg fw-bold color-white d-flex justify-space-between">
			<slot name="title"></slot>
			<slot name="close-button"></slot>
		</v-card-title>

		<v-card-text class="pa-2">
			<v-form @submit.prevent ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
				<!-- <pui-field-set :title="$t(configuration.title)"> -->
				<v-row no-gutters>
					<v-col
						:cols="component.cols || 12"
						:sm="component.sm || 'auto'"
						:md="component.md || 'auto'"
						v-for="(component, index) in configuration.components"
						:key="index"
					>
						<component v-if="component.component" :is="component.component" :componentData="component" @input="inputValue" />
					</v-col>
				</v-row>
				<!-- </pui-field-set> -->
			</v-form>
		</v-card-text>

		<v-divider v-if="action"></v-divider>

		<v-card-actions class="justify-end">
			<slot name="submit-button"></slot>
		</v-card-actions>
	</v-card>
</template>

<script>
// Importa el JSON directamente

import TextComponent from './controls/TextComponent.vue';
import TextAreaComponent from './controls/TextAreaComponent.vue';
import BooleanComponent from './controls/BooleanComponent.vue';
import SelectComponent from './controls/SelectComponent.vue';
import NumberComponent from './controls/NumberComponent.vue';
import SliderComponent from './controls/SliderComponent.vue';

import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	mixins: [PuiFormMethodsMixin],
	components: {
		TextComponent,
		TextAreaComponent,
		BooleanComponent,
		SelectComponent,
		NumberComponent,
		SliderComponent
	},
	props: {
		configuration: {
			type: Object,
			required: true
		},
		action: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			modelLoaded: false,
			formData: {}
		};
	},

	mounted() {
		if (this.model) {
			this.modelLoaded = true;
		}
		window.removeEventListener('keydown', this.keydownPressed);
	},
	methods: {
		/* getComponentType(type) {
			switch (type) {
				case 'text':
					return 'TextComponent';
				case 'textArea':
					return 'TextAreaComponent';
				case 'boolean':
					return 'BooleanComponent';
				case 'select':
					return 'SelectComponent';
				case 'number':
					return 'NumberComponent';
				default:
					return 'div';
			}
		} */
		inputValue(value) {
			console.log('inputValue (ActionForm)', value, Object.keys(value));
			this.formData = { ...this.formData, ...value };
			this.$emit('change', this.formData);
			console.log('formData', this.formData);
		}
	}
};
</script>
