<!-- BooleanComponent.vue -->
<template>
	<pui-checkbox
		:label="$t(componentData.label)"
		:required="componentData.required"
		:style="componentData.style"
		:class="componentData.class"
		v-model="inputData"
	></pui-checkbox>
</template>

<script>
//import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	//mixins: [PuiFormMethodsMixin],

	props: ['componentData'],
	data() {
		return {
			inputData: null
		};
	},
	watch: {
		inputData() {
			console.log('inputData (select)', this.inputData);
			let propName = this.componentData.propertyName || 'value';
			this.$emit('input', { [propName]: this.inputData });
		}
	}
};
</script>
